<script setup lang="ts">
import type { DepositStreakInfo, Promotion } from "@/types";

const props = defineProps<{
	depositInfo: DepositStreakInfo;
	finishedAt: string;
	image: string;
	type?: Promotion["type"];
}>();

const { t } = useT();
const { open } = useAppModals();

const activeDepositCount = computed(() => props?.depositInfo?.progress?.lastIndexOf(true) + 1);
const depositsTotals = computed(() => props?.depositInfo?.progress?.length);

const handleClick = () => {
	if (props.depositInfo?.dailyWinStreakLimitExceeded) {
		return;
	}

	if (props.depositInfo?.canWheel) {
		open("LazyOModalDepositStreak");
		return;
	}

	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const handleHowItWorksModal = () => {
	open("LazyOModalDepositStreakHowItWorks");
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 316,
			height: 392,
			src: image,
			format: 'avif',
			loading: 'lazy'
		}"
		width="316px"
		height="392px"
		class="card-deposit-streak"
		@click="handleClick"
	>
		<template #top>
			<ABadge
				v-if="!(depositInfo.canWheel || depositInfo.dailyWinStreakLimitExceeded)"
				class="label-time"
				background="var(--carabanchel)"
				variant="info"
				autosize
			>
				<AText class="badge text-cannes" :size="12" :modifiers="['semibold', 'uppercase']">
					{{ t("depositStreak.promo.ends", { time: finishedAt }) }}
				</AText>
			</ABadge>
		</template>
		<template #bottom>
			<div class="box">
				<span class="btn-info" @click.stop="handleHowItWorksModal">
					<NuxtIcon name="20/info-pop" class="icon" filled />
				</span>
				<AText class="title" :size="16" :modifiers="['semibold']">
					{{
						depositInfo.canWheel && !depositInfo.dailyWinStreakLimitExceeded
							? t("depositStreak.promo.unlocked")
							: t("depositStreak.promo.title", { max: depositsTotals })
					}}
				</AText>
				<AText class="subtitle">
					{{
						depositInfo.canWheel && !depositInfo.dailyWinStreakLimitExceeded
							? t("depositStreak.promo.claim")
							: t("depositStreak.promo.subtitle", { max: depositInfo?.maxPercentage })
					}}
				</AText>
				<MDepositStreakLevelsBar
					v-if="!depositInfo.dailyWinStreakLimitExceeded"
					:active="activeDepositCount"
					:levels="depositsTotals"
				/>

				<AButton class="action" :variant="depositInfo.dailyWinStreakLimitExceeded ? 'disabled' : 'primary'" size="lg">
					<AText :modifiers="['capitalize', 'semibold']">
						<span v-if="!depositInfo.dailyWinStreakLimitExceeded">
							{{ depositInfo.canWheel ? t("depositStreak.promo.btn.reward") : t("depositStreak.promo.btn.store") }}
						</span>
						<span v-else>{{ t("depositStreak.promo.btn.available") }}</span>
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-deposit-streak {
	color: var(--coro);
	background: var(--graz);
	border: 2px solid var(--celaya);
	border-radius: 16px;
	overflow: visible;

	&:deep(> img) {
		margin: auto;
		border-radius: 14px;
	}

	.badge {
		height: 26px;
		display: flex;
		align-items: center;
		padding-right: 4px;
		padding-left: 4px;
		box-shadow: none;
		white-space: nowrap;
		overflow: hidden;
	}

	.title {
		padding: 2px 0;
	}
}

.label-time {
	border: 2px solid var(--chifeng);
}

.subtitle {
	color: var(--neutral-100);
}

.box {
	position: absolute;
	left: calc(50% - 142px);
	bottom: -28px;
	border-radius: 16px;
	border: 2px solid var(--cordoba);
	background: var(--czestochowa);
	backdrop-filter: blur(45px);
	padding: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	width: 284px;
	height: auto;
	gap: 8px;
}

.btn-info {
	position: absolute;
	z-index: 2;
	cursor: pointer;
	top: 10px;
	right: 10px;

	.icon {
		font-size: 20px;

		&:deep(svg path) {
			fill: var(--ciampea);
		}
	}
}

.action {
	&.disabled {
		background-color: var(--carlsbad);
		color: var(--cracow);
	}
}
</style>
